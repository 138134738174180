import React, { useContext } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo/seo";
import ViewportContext from "../components/utils/viewportContext";

import "./info.scss";

const EMAIL_ADDRESS = "info@tommygenes.co";
const INSTAGRAM_HREF = "https://www.instagram.com/tommy._genes/";
const VIMEO_HREF = "https://vimeo.com/tommygenes";

const InfoPage = ({data}) => {
    const viewport = useContext(ViewportContext);

    const infoText = data.allInfoPage.nodes[0].info_text;

    return (
        <>
            <SEO
                title={"Information - TOMMY GENES"}
                description={infoText}
            />

            <div className={"info-container"}>
                <h3 className={"info-container__content font-secondary"}>
                    {infoText}
                </h3>
            </div>

            <div className={"info-footer font-primary"}>
                <div className={"info-footer__content info-footer__content--label"}>
                    Contact
                </div>
                <div className={"info-footer__content info-footer__content--links"}>
                    <a href={`mailto:${EMAIL_ADDRESS}`} target="_blank">Mail</a>
                    <a href={INSTAGRAM_HREF} target="_blank">Insta</a>
                    {viewport !== 'largeScreen' && (
                        <a href={VIMEO_HREF} target="_blank">Vim</a>
                    )}
                </div>
            </div>
        </>
    );
};

export default InfoPage;

export const query = graphql`
query InfoPageQuery {
  allInfoPage {
    nodes {
      info_text
    }
  }
}
 `;
